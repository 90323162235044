html {
    font-size: 100%;
}
@media screen and (max-width: 600px) {
    html {
        font-size: 75%;
    }
}
body {
    background-color: black;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html,
body {
    user-select: none; /* Standard browsers */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE/Edge */
    -moz-user-select: none; /* Older Firefox */
}
ul,
ol,
p,
li {
    margin: 0;
    padding: 0;
}

@media print {
    html {
        font-size: 10pt !important;
    }
    body {
        background-color: white !important;
    }
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    li,
    a {
        color: black !important;
    }
    h1 {
        font-size: 2rem !important;
    }
    h2 {
        font-size: 1.6rem !important;
    }
    h3 {
        font-size: 1.4rem !important;
    }
    h4 {
        font-size: 1.2rem !important;
    }
    h5 {
        font-size: 1rem !important;
    }
    p,
    li {
        font-size: 1rem !important;
    }
    header {
        background-color: white !important;
        box-shadow: none !important;
    }
    footer {
        display: none;
    }
}
